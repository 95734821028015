<template>
    <div class="mt-n6 div-forgot-password">
        <div class="pt-10 d-block">
            <label
                class="yellow--text text--darken-3 font-weight-bold text-h6 pb-2"
                style="border-bottom: 1px solid;"
            >
                ลืมรหัสผ่าน
            </label>
        </div>
        <v-container class="pt-15 pb-16" v-resize="onResize" :style="{width: windowSize.w + 'px'}">
            <v-form v-model="isValid" ref="form" class="form-forgot-password" @submit.prevent="sendForgotPassword">
                <div class="d-block mx-8 pt-10">
                    <label class="d-block white--text text-left text-caption">กรอกอีเมล เพื่อที่จะตั้งค่ารหัสผ่านใหม่ผ่านกล่องอีเมลของผู้ใช้</label>
                    <v-text-field
                        autofocus
                        color="yellow darken-3"
                        dark
                        label="อีเมล"
                        placeholder="กรอกอีเมล"
                        :rules="emailRules"
                        v-model="email"
                    >
                    </v-text-field>
                </div>
                <v-btn rounded color="yellow darken-3" class="px-10 mt-4" @click="sendForgotPassword">
                    ส่งคำขอรหัสผ่านใหม่
                </v-btn>
            </v-form>
        </v-container>
        <dialog-error
            ref="dialogValidateFail"
            :topic="'ข้อมูลไม่ถูกต้อง'"
            :text="'กรุณากรอกข้อมูลใหม่อีกครั้ง'"
        />
        <dialog-error
            ref="dialogError"
            :topic="'Error'"
            :text="errorMessage"
        />
        <dialog-success
            ref="dialogSuccess"
            :text="'ส่งอีเมลสำหรับรีเซ็ตรหัสผ่านแล้ว โปรดตรวจสอบอีเมลของคุณ'"
            :redirectTo="{ name: 'Login' }"
        />
    </div>
</template>

<script>
import { emailRules } from '../utils/validation-rule';
import DialogError from '../components/DialogError.vue';
import DialogSuccess from '../components/DialogSuccess.vue';
import { sendForgotPassword } from '../models/customer';

export default {
    data: () => ({
        height: 0,
        width: 0,
        isValid: false,
        email: '',
        windowSize: {},
        emailRules,
        errorMessage: ''
    }),
    methods: {
        onResize(){
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },
        async sendForgotPassword(){
            // get profile and update
            this.$refs.form.validate();
            if(!this.isValid){
                this.$refs.dialogValidateFail.show();
            }else{
                const loader = this.$loading.show();
                try{
                    await sendForgotPassword(this.email);
                    this.$refs.dialogSuccess.show();
                }catch(error){
                    this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                    this.$refs.dialogError.show();
                }finally{
                    loader.hide();
                }
            }
        }
    },
    computed: {
    },
    async mounted(){
    },
    watch: {
    },
    components: {
        DialogError,
        DialogSuccess
    }
};
</script>

<style scoped>
    .div-forgot-password{
        text-align: center;
    }
    .form-forgot-password{
        background-color: #0F0E0D;
        border-radius: 8px;
        padding-bottom: 32px;
    }
    
</style>

<style>
    .v-radio .v-icon {
        background-image: radial-gradient(#cccccc 40%, transparent 10%);
    }
</style>